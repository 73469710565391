<template>
  <div class="Part">
    <div class="input">
      <!-- 功能按钮 -->
      <el-row>
        <el-button type="primary" size="mini" @click="addNotice" v-if="authe.Hotel_Administration_Notice_AddAnnouncement">添加公告</el-button>
        <el-button type="primary" size="mini" @click="modifyNotice" v-if="authe.Hotel_Administration_Notice_ModifyAnnouncement">修改公告</el-button>
        <el-button type="primary" size="mini" @click="deleteNotice" v-if="authe.Hotel_Administration_Notice_DeleteAnnouncement">删除公告</el-button>

        <!-- 公告对话框开始 添加 修改-->
        <el-dialog :modal-append-to-body='false' 
          :title="noticeDialogTitle"
          :visible.sync="addNoticeDialog"
          :close-on-click-modal="false"
          @close="cleanNoticeDialog"
          v-loading='loading'
          width="800px"
          @open="show"
        >
          <el-form :model="noticeFrom" ref="notice" :rules="noticeFromRules">
            <!-- 第一行 -->
            <el-row :gutter="20">
              <el-col :span="4">
                <el-form-item>公告标题：</el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item prop="title">
                  <el-input
                    autocomplete="off"
                    size="small"
                    v-model="noticeFrom.title"
                    :readonly="isReadonly"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第二行 -->
            <el-row :gutter="20">
              <el-col :span="4">
                <el-form-item>公告类型：</el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item prop="type">
                  <el-select
                    v-model="noticeFrom.type"
                    style="width:100%;"
                    size="small"
                    :disabled="isReadonly"
                  >
                    <el-option label value></el-option>
                    <el-option label="公司" value="公司"></el-option>
                    <el-option label="业务部" value="业务部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="行政部" value="行政部"></el-option>
                    <el-option label="租客" value="租客"></el-option>
                    <el-option label="业主" value="业主" v-if="!authe.Hotel_Lease_NoLandlord"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="4">
                <el-form-item>内容摘要：</el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item>
                  <el-input
                    autocomplete="off"
                    size="small"
                    v-model="noticeFrom.dnSummary"
                    :readonly="isReadonly"
                    @input="onInput()"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第三行 -->
            <el-row :gutter="20">
              <el-col :span="4">
                <el-form-item>选择上传文件：</el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item>
                  <el-upload
                    class="upload-demo"
                    action="https://upload-z2.qiniup.com"
                    :data="picData"
                    :file-list="noticeFrom.imgUploadList"
                    list-type="picture"
                    :before-upload="beforeUpload"
                    :on-success="handleAvatarSuccess"
                    :before-remove="beforeRemove"
                    :on-remove="handleRemove"
                    :on-preview="handlePictureCardPreview"
                  >
                    <el-button size="small" type="primary">上传附件</el-button>
                    <el-button size="small" type="primary" @click.stop="phoneUpload">手机上传</el-button>
                    <el-button
                      size="small"
                      type="primary"
                      @click.stop="getTempImg(noticeFrom.random)"
                    >
                      <i class="el-icon-refresh"></i>
                    </el-button>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第四行 -->
            <el-row :gutter="20">
              <el-col :span="24">
                <quill-editor
                  class="ql-editor"
                  v-model="noticeFrom.content"
                  ref="myQuillEditor"
                  :options="editorOption"
                ></quill-editor>
              </el-col>
            </el-row>
            <!-- 第五行 -->
            <el-row :gutter="20" v-show="!isAddNotice">
              <el-col :span="4">
                <el-form-item>发布时间：</el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item>
                  <el-input
                    autocomplete="off"
                    size="small"
                    v-model="noticeFrom.releaseTime"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!--富文本图片上传-->
          <el-upload
            :data="picData2"
            class="avatar-uploader"
            action="http://upload-z2.qiniup.com"
            :before-upload="beforeUpload2"
            :on-success="success"
            :on-error="handleError"
            :show-file-list="false"
            style="display: none"
          >
            <i class="el-icon-plus avatar-uploader-icon" id="imgInput"></i>
          </el-upload>

          <div slot="footer">
            <el-button @click="addNoticeDialog = false">取 消</el-button>
            <el-button
              type="primary"
              @click="saveNotice('notice')"
              v-show="isAddNotice && !isReadonly"
            >保 存</el-button>
            <el-button
              type="primary"
              @click="saveModifyNotice('notice')"
              v-show="!isAddNotice && !isReadonly"
            >修 改</el-button>
          </div>
        </el-dialog>
        <!-- 公告对话框结束 -->

        <!-- 删除公告对话框开始 -->
        <el-dialog :modal-append-to-body='false' 
          title="温馨提示"
          @close="cleanNoticeDialog"
          :visible.sync="deleteNoticeDialog"
          width="30%"
        >
          <span>确定要删除此条公告吗？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="deleteNoticeDialog = false">取 消</el-button>
            <el-button type="primary" @click="deleteSave">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除公告对话框结束 -->
      </el-row>
    </div>
    <!-- 数据表 -->
    <div style="text-align:center;">
      <el-table
        :data="noticeData"
        style="width: 100%"
        highlight-current-row
        :height="tableHeight"
        @row-click="rowClick"
        @row-dblclick="dbClick"
      >
        <el-table-column align="center" prop="dnType" label="类型"></el-table-column>
        <el-table-column align="center" prop="dnTitle" label="标题"></el-table-column>
        <el-table-column align="center" prop="suStaffName" label="发布人"></el-table-column>
        <el-table-column align="center" prop="dnTime" label="发布时间"></el-table-column>
      </el-table>
      <!--   -->
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <!-- 公告详细 -->
    <el-dialog :modal-append-to-body='false'  :visible.sync="noticeDetailDia" @close="cleanNoticeDialog" :close-on-click-modal="false" width="800px">
      <div class="project-detail">
        <div class="title">
          <i class="el-icon-star-on" style="color:#F4C902; margin-right:5px;"></i>
          {{noticeFrom.title}}
          <span>[{{noticeFrom.type}}]</span>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="24">
              <span>发布时间：</span>
              {{noticeFrom.releaseTime}}
            </el-col>
          </el-row>
        </div>
      </div>
      <el-button plai size="mini" @click="accessory" v-if="authe.Hotel_Administration_Notice_Annex">
        <i class="el-icon-picture-outline-round"></i>附件
      </el-button>
      <el-divider></el-divider>
      <p v-html="noticeFrom.content" style="min-height:250px;"></p>
    </el-dialog>
    <!-- 查看原图 -->
    <el-dialog :modal-append-to-body='false'  :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <!-- 手机上传 -->
    <el-dialog :modal-append-to-body='false' 
      :visible.sync="phoneUploadDia"
      title="微信扫一扫上传"
      width="280px"
      :close-on-click-modal="false"
      @close="closePhoneUploadDia"
    >
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="24">
          <canvas id="QRCode"></canvas>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog :modal-append-to-body='false' 
      :visible.sync="accessoryDia"
      width="800px"
      :close-on-click-modal="false"
      title="查看附件"
      @close="closeAccessory"
    >
      <div style="min-height:400px">
        <el-upload
          class="upload-demo"
          action="https://upload-z2.qiniup.com"
          :file-list="noticeFrom.imgUploadList"
          :on-preview="handlePictureCardPreview"
          list-type="picture"
          disabled
        ></el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode"; //引入生成二维码插件
import { dateFtt } from "../../assets/utils";
import * as Quill from "quill";
import { mapState } from "vuex";
import resizeImage from 'quill-image-resize-module' // 调整大小组件。
Quill.register('modules/resizeImage ', resizeImage )
export default {
  name:'announcement',
  data() {
    return {
      loading:false,
      isAddNotice: true,
      isReadonly: false,
      addNoticeDialog: false,
      deleteNoticeDialog: false,
      dialogVisible: false,
      noticeDetailDia: false,
      phoneUploadDia: false,
      accessoryDia: false,

      noticeDialogTitle: "",
      noticeFrom: {
        title: "",
        type: "",
        imgUploadList: [],
        imgDelId: [],
        content: "",
        releaseTime: "",
        dnId: "",
        dnState: "",
        dnSummary:''
      },
      editorOption: {
        modules: {
        imageResize: {
          displayStyles: {
            backgroundColor: "black",
            border: "none",
            color: "white"
          },
          modules: [ 'Resize', 'DisplaySize'] 
        },
        toolbar: [
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link', 'image'],  //链接与图片
          [{ 'color': [] }, { 'background': [] }], //颜色和背景色
          ["bold", "italic", "underline", "strike"],       // 加粗 斜体 下划线 删除线
          [{ indent: "-1" }, { indent: "+1" }],            // 缩进
          [{ size: ["small", false, "large", "huge"] }],   // 字体大小
          [{ header: [1, 2, 3, 4, 5, 6] }],         // 标题
          [{ align: [] }],                                 // 对齐方式
          ["clean"],                                       // 清除文本格式
        ],
          history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false
          },
        }
      },
      noticeFromRules: {
        title: [{ required: true, message: "输入公告标题", trigger: "change" }],
        type: [{ required: true, message: "请选择公告类型", trigger: "change" }]
      },
      picData: {
        token: "",
        "x:co": "",
        "x:filename": "",
        "x:photoRandom": "", //十二位随机数
        "x:photoTableType": "", //审批or任务
        "x:photoMimeType": "", //文件or图片
        "x:photoBucket": "" //公用or私有
      },
      picData2: {
        token: ""
      },
      dialogImageUrl: "",

      noticeData: [],
      noticeRow: "",
      tableHeight: window.innerHeight - 185,

      total: 0,
      pageSize: 10,
      currentPage: 1,
      // 图片相关
      picData: {
        token: "",
        "x:co": "",
        "x:filename": "",
        "x:photoRandom": "", //十二位随机数
        "x:photoTableType": "", //审批or任务
        "x:photoMimeType": "", //文件or图片
        "x:photoBucket": "" //公用or私有
      },
      QRCodeMsg: "",
      detailImgList: []
    };
  },
  computed: {
    ...mapState(['authe','pageAuth'])
  },
  created() {
    if (this.pageAuth['/announcement']) {
      this.getNoticData();
      this.getToken();
    } else {
      this.$router.replace('/notfind')
    }
  },
  methods: {
    //打开添加公告对话框
    addNotice() {
      this.noticeDialogTitle = "添加公告";
      this.noticeFrom.random = parseInt((Math.random() * 9 + 1) * 100000000000);
      this.isAddNotice = true;
      this.addNoticeDialog = true;
      this.isReadonly = false;
      this.$nextTick(() => {
        this.$refs.notice.resetFields();
      });
    },
    //打开修改公告对话框
    modifyNotice() {
      if (!this.noticeRow) {
        this.$message.warning("请先选中一条记录");
      } else {
        this.noticeDialogTitle = "修改公告";
        this.isAddNotice = false;
        this.addNoticeDialog = true;
        this.isReadonly = false;

        this.getPhoto();
        this.noticeFrom.random = parseInt(
          (Math.random() * 9 + 1) * 100000000000
        );
        this.noticeFrom.title = this.noticeRow.dnTitle;
        this.noticeFrom.type = this.noticeRow.dnType;
        this.noticeFrom.content = this.noticeRow.dnContent;
        this.noticeFrom.releaseTime = this.noticeRow.dnTime;
        this.noticeFrom.dnId = this.noticeRow.dnId;
        this.noticeFrom.dnState = this.noticeRow.dnState;
        this.noticeFrom.dnSummary=this.noticeRow.dnSummary
      }
    },
    //新增公告保存
    saveNotice(formName) {
      if (this.noticeFrom.content == "") {
        this.$message.error("请输入公告内容");
        return false;
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.loading=true
            let imgArr = [];
            for (let i = 0; i < this.noticeFrom.imgUploadList.length; i++) {
              let obj = {
                path:
                  "http://pic-gongkai.fangzhizun.com/" +
                  this.noticeFrom.imgUploadList[i].response.key,
                name: this.noticeFrom.imgUploadList[i].name
              };
              imgArr.push(obj);
            }
            let time = dateFtt("yyyy-MM-dd hh:mm:ss", new Date());
            this.$axios({
              url: "/common1/dashAnnouncements",
              method: "post",
              data: {
                dnContent: this.noticeFrom.content,
                dnState: "正常",
                dnTime: time,
                dnSummary:this.noticeFrom.dnSummary,
                dnTitle: this.noticeFrom.title,
                dnType: this.noticeFrom.type,
                fileImgNum: `${this.noticeFrom.imgUploadList.length}/0`,
                fileImgPath: JSON.stringify(imgArr)
              }
            })
              .then(res => {
                console.log(res);
                  this.$message.success("添加成功");
                  this.addNoticeDialog = false;
                  this.getNoticData();
                  this.cleanNoticeDialog();
                  this.loading=false
                
              }).catch(err=>{
                this.isError(err)
                this.loading=false
              })
          } else {
            return false;
          }
        });
      }
    },
    //修改公告保存
    saveModifyNotice(formName) {
      if (this.noticeFrom.content == "") {
        this.$message.error("请输入公告内容");
        return false;
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.loading=true

            this.$axios({
              url: `/common1/dashAnnouncements/${this.noticeFrom.dnId}`,
              method: "put",
              data: {
                dnContent: this.noticeFrom.content,
                dnState: this.noticeFrom.dnState,
                dnTitle: this.noticeFrom.title,
                dnType: this.noticeFrom.type,
                randomNum: this.noticeFrom.random,
                deleteImgList: this.noticeFrom.imgDelId,
                dnSummary:this.noticeFrom.dnSummary,
                tableType: "公告"
              }
            }).then(res => {
              console.log(res);
              this.$message.success("修改成功");
              this.addNoticeDialog = false;
              this.getNoticData();
              this.cleanNoticeDialog();
              this.loading=false
            });
          } else {
            return false;
          }
        }).catch(err=>{
          this.loading=false
          this.isError(err)
        });
      }
    },
    //关闭清除
    async cleanNoticeDialog() {
      if (this.noticeDialogTitle == "修改公告") {
        await this.recoverImg();
      }
      console.log("清楚了");
      
      this.noticeFrom = {
        title: "",
        type: "",
        imgUploadList: [],
        imgDelId: [],
        content: "",
        releaseTime: "",
        dnId: "",
        dnState: ""
      };
      console.log(this.noticeFrom);
      
    },

    //打开删除公告对话框
    deleteNotice() {
      if (!this.noticeRow) {
        this.$message.warning("请先选中一条记录");
      } else {
        this.deleteNoticeDialog = true;
      }
    },
    deleteSave() {
      console.log(this.noticeRow);
      this.$axios({
        url: `/common1/dashAnnouncements/${this.noticeRow.dnId}`,
        method: "delete"
      }).then(res => {
        this.$message.success("删除成功");
        this.deleteNoticeDialog = false;
        this.getNoticData();
      }).catch(err=>{
        this.isError(err)
      });
    },

    // 双击打开详细
    dbClick(row) {
      if(!this.authe.Hotel_Administration_Notice_ModifyAnnouncement){
        return
      }
      this.modifyNotice()
    },

    // 选中某一行
    rowClick(row) {
      console.log(row);
      this.noticeRow = row;
    },

    //获取公告列表
    getNoticData() {
      this.$axios({
        url: "/common1/dashAnnouncements",
        method: "get",
        params: {
          limit: this.pageSize,
          cursor: this.currentPage
        }
      }).then(res => {
        console.log(res);
        this.noticeData = res.data.result.records;
        this.total = res.data.result.total;
      }).catch(err=>{
        this.isError(err)
      });
    },
    //选择数据条数
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getNoticData();
    },
    //具体那一页
    currentChange(current) {
      this.currentPage = current;
      this.noticeRow = "";
      this.getNoticData();
    },

    //打开附件
    accessory() {
      this.getPhoto();
      this.accessoryDia = true;
    },
    //关闭附件
    closeAccessory() {
      this.noticeFrom.imgUploadList = [];
    },

    //手机上传
    phoneUpload() {
      if (this.noticeFrom.noticeDialogTitle == "添加公告") {
        this.getPhoneUploadUrl(this.noticeFrom.random, "私有", "图片", null);
      } else {
        this.getPhoneUploadUrl(
          this.noticeFrom.random,
          "私有",
          "图片",
          this.noticeFrom.dnId
        );
      }
      this.phoneUploadDia = true;
    },
    //关闭手机上传
    closePhoneUploadDia() {
      this.getTempImg(this.noticeFrom.random);
    },
    //恢复图片
    recoverImg() {
      this.$axios({
        url: `/common1/photos/recovery/${this.noticeFrom.dnId}`,
        method: "put",
        params: {
          photoTableType: "公告"
        }
      });
    },
    //获取手机上传url
    getPhoneUploadUrl(random, bucket, fileType, tableId) {
      this.$axios({
        url: `/common1/photos/getMobUploadUrl`,
        method: "get",
        params: {
          photoRandom: random,
          photoBucket: bucket,
          photoMimeType: fileType,
          photoTableType: "公告",
          photoTableId: tableId,
          photoUrl: null,
          photoUserId: null
        }
      }).then(res => {
        console.log(res.data.result);
        this.QRCodeMsg = res.data.result;
      });
    },
    //查询临时图片
    async getTempImg(random) {
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoRandom: random,
            photoTableType: "公告",
            photoStatus: "临时"
          }
        }).then(res => {
          imgdata = res.data.result;
        });
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        if (this.noticeFrom.noticeDialogTitle == "添加公告") {
          if (this.noticeFrom.imgUploadList.length == 0) {
            this.noticeFrom.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.noticeFrom.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.noticeFrom.imgUploadList.push(item1);
              }
            }
          }
        } else {
          if (this.noticeFrom.imgUploadList.length == 0) {
            this.noticeFrom.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.noticeFrom.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.noticeFrom.imgUploadList.push(item1);
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    //查询已保存的图片
    async getPhoto() {
      this.noticeFrom.dnId = this.noticeRow.dnId;
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoTableId: this.noticeFrom.dnId,
            photoTableType: "公告",
            photoStatus: "正常"
          }
        }).then(res => {
          imgdata = res.data.result;
        });
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        this.noticeFrom.imgUploadList = imgArr;
      } catch (err) {
        console.log(err);
      }
    },
    //获取下载凭证
    getDownloadToken(str) {
      return this.$axios({
        url: `/common1/photos/getDownloadUrl`,
        method: "get",
        params: {
          baseUrls: str
        }
      }).then(res => {
        return res.data.result[0].original;
      });
    },
    //获取token
    getToken() {
      return this.$axios({
        url: "/common1/photos/token/callback",
        method: "get",
        params: {
          bucket: "私有"
        }
      }).then(res => {
        return res.data.result;
      });
    },

    //上传前
    async beforeUpload(file) {
      try {
        console.log(file);
        let getToken = await this.getToken();
        this.picData.token = getToken;
        if(file.type == "image/jpeg" || file.type == "image/jpg" || file.type === "image/gif" || file.type == "image/png" || file.type == "image/bmp"){
          this.picData["x:photoMimeType"] = "图片"
        }else{
          this.picData["x:photoMimeType"] = "文件"
        }
        this.picData["x:filename"] = file.name;
        this.picData["x:co"] = sessionStorage.getItem("co");
        this.picData["x:photoTableType"] = "公告";
        this.picData["x:photoBucket"] = "私有";

        if (this.noticeFrom.noticeDialogTitle == "添加公告") {
          this.picData["x:photoRandom"] = this.noticeFrom.random;
        } else {
          this.picData["x:photoTableId"] = this.noticeFrom.dnId;
          this.picData["x:photoRandom"] = this.noticeFrom.random;
        }
      } catch (err) {
        console.log(err);
      }
    },
    //上传成功后
    handleAvatarSuccess(res, file, fileList) {
      this.noticeFrom.imgUploadList = fileList;
    },
    //删除前
    beforeRemove(file) {
      console.log(file);
      if (this.noticeFrom.noticeDialogTitle == "添加公告") {
        return this.$confirm(`确定移除该文件？`).then(() => {
          this.$axios({
            url: `/common1/photos`,
            method: "DELETE",
            params: {
              bucket: "私有",
              photoIds: file.response.result
            }
          })
            .then(res => {
              console.log(res);
            })
            .catch(err => {
              this.isError(err)
            });
        });
      } else {
        return this.$confirm(`确定移除该文件？`).then(() => {
          this.noticeFrom.imgDelId.push(file.response.result);
          this.$axios({
            url: `/common1/photos/failure/${file.response.result}`,
            method: "put"
          }).then(res => {});
        });
      }
    },
    //删除后
    handleRemove(file, fileList) {
      this.noticeFrom.imgUploadList = fileList;
    },
    //查看某张图片的原图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file);
    },
    //上传失败
    handleError(res) {
      //显示错误
      console.log(res);
    },

    //富文本
    show() {
      this.$nextTick(() => {
        this.$refs.myQuillEditor.quill
          .getModule("toolbar")
          .addHandler("image", this.imgHandler);
      });
    },
    success(res, file, fileList) {
      //图片的远程路径
      let value = "http://pic-gongkai.fangzhizun.com/" + res.key;
      // 将图片添加到富文本内容区域
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      // 调用编辑器的 insertEmbed 方法，插入URL
      this.$refs.myQuillEditor.quill.insertEmbed(
        this.addRange !== null ? this.addRange.index : 0,
        "image",
        value,
        Quill.sources.USER
      );
    },
    imgHandler(state) {
      // 点击图片ICON触发事件
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (state) {
        // 选择并上传一张图片
        let fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    //获取无回调token
    getToken2() {
      return this.$axios({
        method: "get",
        url: "/common1/photos/token",
        params: {
          bucket: "公有"
        }
      }).then(res => {
        //获取 token
        return res.data.result;
      });
    },
    async beforeUpload2() {
      //上传完文件前获取token
      try {
        let token = await this.getToken2();
        this.picData2.token = token;
      } catch (err) {
        console.log(err);
      }
    },
    onInput(){
      this.$forceUpdate();
    }
  },
  watch: {
    // 通过监听获取数据
    QRCodeMsg(val) {
      // 获取页面的canvas
      var msg = document.getElementById("QRCode");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, val, function(error) {
        console.log(error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>